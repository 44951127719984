<template>
  <div>
    <main>
      <!-- <section id="what">
        <b-container class="py-5">
          <b-row class="mx-0">
            <b-col
              class="mx-auto"
              lg="8"
            >
              <h1 class="mt-5 text-center text-uppercase">
                Soutenir Emancip’Asso
              </h1>
              <div
                class="pt-5"
                v-html="$t('what')"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section id="who">
        <b-container class="py-5">
          <b-row class="mx-0">
            <b-col
              lg="8"
              class="mx-auto"
            >
              <div v-html="$t('who')"></div>

              <div
                id="copil"
                class="card"
                v-html="$t('copil')"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section id="mecenes">
        <b-container class="py-5">
          <h2>Ils nous soutiennent déjà</h2>

          <partner
            v-for="partner in Object.keys(this.$t('partners'))"
            :key="partner"
            :partner="partner"
          />
        </b-container>
      </section> -->
      <h1 class="text-center mt-5">
        <img
          alt="Soutenir Emancip'asso"
          :src="`${$t('baseurl')}img/emancipasso.png`"
        />
      </h1>

      <sfs
        :offpay="false"
        :oto="true"
      />
    </main>
  </div>
</template>

<script>
// import Partner from '../components/Partner.vue';
import sfs from '../../commons/components/sfs/Main.vue';

export default {
  components: {
    // Partner,
    sfs,
  },

  created() {
    // Add [data-*] attributes for CSS
    const html = document.getElementsByTagName('html');
    html[0].setAttribute('data-inframe', this.$t('inframe'));
  },
};
</script>

<style lang="scss">
#home {
  --e-r1: #dc674c;
  --e-r2: #fae7e2;
  --e-b0: #427C7F;
  --e-b1: #50bcbc;
  --e-b2: #c3f2f2;
  --e-b3: #ecfcfc;

  /* New colors */
  --e-j1: #ffc44d;
  --e-j2: #ffe6b4;
  --e-j3: #fff8ea;
  --e-p1: #e50666;
  --e-b1: #117d95;
  --e-g1: #141412;

  background: var(--e-j3);

  h1 {
    font-weight: bold;
    padding: 2rem;
    color: var(--e-g1);
  }

  h2 {
    line-height: 1.75;
    margin-bottom: 1rem;
    color: var(--e-g1);
  }

  .container {
    background: #fff;
  }
}

#what, #who {
  h2 {
    color: var(--e-b0);
    border-bottom: 5px solid var(--e-r1);
  }
}

#who {
  background: var(--e-b2);

  h3 {
    display: inline-block;
    color: var(--e-r1);
    line-height: 1.5;
    margin-bottom: .75rem;
  }

  img {
    width: 150px;
    height: 150px;
    border: 2px solid var(--f-g3);
    border-radius: .5rem;
    padding: .25rem;
    margin: 1rem 0 1rem 1rem;
    float: right;
  }
}

#copil {
  text-align: center;
  max-width: 850px;
  margin: 1rem auto;
  border: none;

  ul {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      width: 140px;
      font-size: 12px;
      margin: 1rem .75rem;

      img {
        width: 100px;
        height: 100px;
        margin: 0;
        margin-bottom: .125rem;
        float: none;
      }

      a {
        font-size: 16px;
        line-height: 30px;
        background: none;
      }
    }
  }

  p {
    clear: left;
    margin-top: 20px;
    padding: 0 1rem;
  }
}

a {
  text-decoration: underline !important;
}

#mecenes {
  h2 {
    color: var(--e-r1);
  }

  .container {
    background: transparent;
  }

  img {
    object-fit: scale-down;
    background: #fff;
    border-radius: 100px;
    width: 200px;
    height: 200px;
  }

  .feb-v2 {
    background: var(--e-b3);

    h2 {
      margin-bottom: 0;
      line-height: 1.25;
      a {
        color: var(--e-b0);
        text-decoration: none !important;
      }
    }

    p a {
      color: var(--f-g6);
      text-decoration: none !important;
    }

    img { border: 5px solid var(--e-b1); }

    &.row {
      border-top: 1px solid;
      border-image: radial-gradient(var(--e-b1), transparent) 1;
    }
  }
}

small.date-ajout {
  display: none; // Don't show when they were added for now
}

#f-sfs, #support {
  background: var(--e-j3) !important;
}

#f-sfs #support {
  .onetime {
    --contextual1: var(--e-p1);
    --contextual2: var(--e-b1);
  }

  h2 {
    color: var(--e-g1);
    background: none;
    font-family: inherit;
    font-size: 2rem;
    line-height: 1.75;
    padding: 0;
    text-align: left;
    text-transform: none;
    transform: none;
  }

  .fb-g2 {
    background: var(--f-j1) !important;
  }

  .fc-g5 {
    color: var(--f-j2);
  }

  .fc-g6 {
    color: var(--f-j3);
  }

  #privacy .fc-g7 {
    color: var(--e-b1);
  }
}

#f-sfs-form {
  margin-top: 1rem;

  .list-unstyled.text-secondary, // links about frama
  #whoAreYou > div > div:nth-child(5) { // survey
    display: none;
  }

  > .row {
    justify-content: center;

    > .col-lg-5 {
      display: none;
    }
  }
}

[data-inframe="true"] main {
  h1 {
    display: none;
  }

  .container {
    max-width: none;
    width: 100%;
  }

  #f-sfs-form {
    > .row {
      > .col-lg-7 {
        flex: auto;
        max-width: none;
      }
    }
  }
}
</style>
